import { Controller } from "stimulus";

function renderThankYouText(amount) {
  if (!amount || amount <= 0 || amount === "diff" ) return "";

  return `
    <h2 class="thank-you__title">
      Дякуємо!
    </h2>
    <div class="thank-you__text">
      Ви заправите Тарілкомобіль на маршрут в ${Math.round(amount/15)} магазинів
    </div>
  `;
}

const hiddenClass = "payment-form__element-hidden";

const helpOrgsUrlsObject = {
  monthly_100: "https://secure.wayforpay.com/button/b01de79780f8c",
  monthly_500: "https://secure.wayforpay.com/button/b4f3cc3ea7b3c",
  monthly_diff: "https://secure.wayforpay.com/button/b671cd4613f76",
  one_time_100: "https://secure.wayforpay.com/button/b38fcfe243a71",
  one_time_500: "https://secure.wayforpay.com/button/b545965e898b1",
  one_time_diff: "https://secure.wayforpay.com/button/bb29f07d4a62d",
};

export default class extends Controller {
  static targets = [
    "submit",
    "orgSubmit",
    "otherAmountInput",
    "otherAmountRadio",
    "otherAmountInputWrapper",
    "otherOrgAmountInput",
    "thankYouWrapper",
  ];

  static values = {
    monthlySubmitText: String,
    oneTimeSubmitText: String,
  };

  initialize() {
    this.currenHelptType = "help_needy";
    this.currentType = "monthly";
    this.currentAmount = 0;
    this.confirmed = false;
  }

  typeChanged(e) {
    const value = e.target.value;
    this.currentType = e.target.value;
    const newLabel = this.getSubmitLabelFromType(value);

    if (!newLabel) return;

    this.submitTarget.value = newLabel;
    this.submitTarget.setAttribute("data-disable-with", newLabel);
    this.orgSubmitTarget.innerHTML = newLabel;

    this.confirmButtonDisableEnableCheck();
    this.setHelpOrgUrl();
    this.updateThankYouText();
  }

  helpTypeChanged(e) {
    const helpType = e.target.value;
    this.currenHelptType = helpType;

    const helpElements = ["otherAmountInput", "otherAmountInputWrapper", "submit"];
    const orgHelpElements = ["orgSubmit", "otherOrgAmountInput"];

    if (helpType == 'support_organization') {
      helpElements.forEach(el => this[`${el}Target`].classList.add(hiddenClass));
      orgHelpElements.forEach(el => this[`${el}Target`].classList.remove(hiddenClass));
    } else {
      helpElements.forEach(el => this[`${el}Target`].classList.remove(hiddenClass));
      orgHelpElements.forEach(el => this[`${el}Target`].classList.add(hiddenClass));
    }
    this.confirmButtonDisableEnableCheck();
    this.setHelpOrgUrl();
    this.updateThankYouText();
  }

  otherAmountFocused(e) {
    this.otherAmountRadioTarget.checked = true;
  }

  otherAmountChanged(e) {
    this.currentAmount = e.target.value;
    this.confirmButtonDisableEnableCheck();
    this.setHelpOrgUrl();
    this.updateThankYouText();
  }

  otherAmountBlured(e) {
    if (!this.otherAmountInputTarget.value) {
      const newValueInput = this.element.querySelector("input#amount_100");
      newValueInput.checked = true;
    }
    this.confirmButtonDisableEnableCheck();
    this.setHelpOrgUrl();
    this.updateThankYouText();
  }

  // resetOther(e) {
  //   this.otherAmountInputTarget.value = "";
  //   this.updateThankYouText();
  // }

  // labelFocused(e) {
  //   const newValueInput = this.element.querySelector(
  //     `#${e.target.getAttribute("data-for-label")}`
  //   );
  //   newValueInput.checked = true;
  //   this.otherAmountInputTarget.value = "";
  //   this.confirmButtonDisableEnableCheck();
  //   this.setHelpOrgUrl();
  //   this.updateThankYouText();
  // }

  amountFocused(e) {
    this.currentAmount = e.target.value;
    this.confirmButtonDisableEnableCheck();
    this.setHelpOrgUrl();
    this.updateThankYouText();
  }

  confirm(e) {
    this.confirmed = e.target.checked;
    this.confirmButtonDisableEnableCheck();
    this.setHelpOrgUrl();
    this.updateThankYouText();
  }

  updateThankYouText() {
    if (this.currenHelptType === "help_needy") {
      this.thankYouWrapperTarget.innerHTML = renderThankYouText(this.currentAmount);
    } else {
      this.thankYouWrapperTarget.innerHTML = `
      <h2 class="thank-you__title">
        Дякуємо!
      </h2>`
    }
  }

  getSubmitLabelFromType(type) {
    switch (type) {
      case "one_time":
        return this.oneTimeSubmitTextValue;
      case "monthly":
        return this.monthlySubmitTextValue;
      default:
        return;
    }
  }

  confirmButtonDisableEnableCheck(){
    let amount = this.currentAmount;
    let amountSet = amount && (amount === 'diff' || amount > 0)
    if (amountSet && this.confirmed) {
      this.orgSubmitTarget.classList.remove("payment-form__wayforpay-link-disabled");
      this.submitTarget.disabled = false;
    } else {
      this.orgSubmitTarget.classList.add("payment-form__wayforpay-link-disabled");
      this.submitTarget.disabled = true;
    }
  }

  setHelpOrgUrl() {
    let url = helpOrgsUrlsObject[`${this.currentType}_${this.currentAmount}`] || helpOrgsUrlsObject[`${this.currentType}_diff`]
    this.orgSubmitTarget.setAttribute("href", url);
  }
}
